@mixin noUnderline {
  border-bottom: none;

  &:hover {
    border-bottom: none;
  }
}

@function rem($pixels, $context: $mainFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin list-reset() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin placeholder($font, $size, $color) {
  &::-webkit-input-placeholder {
    font: {
      size: $size;
      family: $font;
    }
    color: $color;
  }
  &::-moz-placeholder {
    font: {
      size: $size;
      family: $font;
    }
    color: $color;
  }
  &:-ms-input-placeholder {
    font: {
      size: $size;
      family: $font;
    }
    color: $color;
  }
}

