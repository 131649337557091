.offer {
  padding: 60px 0;
  
  @media (max-width: $phablet-width) {
    padding: 40px 0;
  }
  
  @media (max-width: $mobile-width) {
    padding: 20px 0;  
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  &__slider,
  &__body {
    width: 47%;
    
    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }

  &__slider {
    margin-right: 60px;
    
    @media (max-width: $tablet-width) {
      margin-bottom: 60px;
    }
    
    @media (max-width: $mobile-width) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__body {
    flex-grow: 1;
    
    @media (max-width: $tablet-width) {
      width: 40%;
    }
  }

  &__title {
    margin-top: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.21;
    
    @media (max-width: $tablet-width) {
      font-size: 36px;
    }
    
    @media (max-width: $phablet-width) {
      font-size: 24px;
    }
    
    @media (max-width: $mobile-width) {
      font-size: 18px;
    }
  }

  small {
    display: block;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.34;
    color: $neutral-secondary;
  }

  &__info {
    color: $neutral-secondary;
    line-height: 22px;
  }

  &__note {
    margin: 30px 0 40px;
    padding: 20px;
    font-weight: 700;
    color: $mainFontColor;
    background-color: $warning-color;
    border-radius: 6px;
  }

  /* Стрелки слайдера */
  .slider__list {
    max-width: 460px;
    margin: 0 auto;
  }

  .slider__item {
    padding: 3px 0;
  }

  .slick-prev {
    left: -60px;
    /*background: url(/img/icon-left.svg) no-repeat 50%/70%;*/
  }

  .slick-next {
    right: -60px;
  }
}
