.main-nav {
  max-height: 100px;
  margin-right: 40px;
  
  @media (max-width: $tablet-width) {
    margin-right: 20px;
  }
  
  @media (max-width: $phablet-width) {
    max-height: 60px;
    margin-right: 20px;
  }

  &__toggle {
    position: relative;
    width: 100px;
    height: 100px;
    font-size: 0;
    text-align: left;
    background-color: $default-secondary;
    border: 0;
    cursor: pointer;
    
    @media (max-width: $phablet-width) {
      width: 60px;
      height: 60px;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 45px;
      height: 3px;
      background-color: $mainFontColor;
      transform: translate(-50%, -50%);
      transition-duration: 0.4s;
      
      @media (max-width: $phablet-width) {
        width: 28px;
        height: 2px;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 45px;
        height: 3px;
        background-color: $mainFontColor;
        transition-duration: 0.4s;
        
        @media (max-width: $phablet-width) {
          width: 28px;
          height: 2px;
        }
      }
      &::before {
        top: -14px;
        
        @media (max-width: $phablet-width) {
          top: -8px;
        }
      }
      &::after {
        top: 14px;
        
        @media (max-width: $phablet-width) {
          top: 8px;
        }
      }
    }

    &:hover,
    &:focus {
      outline: none;

      span {
        background-color: $primary-color;

        &::before,
        &::after {
          background-color: $primary-color;
        }
      }
    }
  }

  &--closed {
    .main-nav__wrapper {
      display: none;
    }
  }

  &--opened {
    .main-nav__wrapper {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;
        width: 50%;
        height: 100%;
        background-color: #ffffff;
        
        @media (max-width: $tablet-width) {
          display: none;
          background-color: transparent;
        }
      }
      /*display: block;*/
      /*overflow: hidden;*/
      /*animation-name: slideLeft;
      animation-duration: 0.6s;*/
    }

    .main-nav__toggle {
      background-color: $primary-color;

      span {
        background-color: transparent;

        &::before,
        &::after {
          background-color: $default-color;
        }

        &::before {
          top: 0;
          transform: rotate(-45deg);
        }

        &::after {
          top: 0;
          transform: rotate(45deg);
        }
      }

      &-text {
        position: absolute;
        bottom: 10px;
        left: 50%;
        margin: 0;
        font-size: 12px;
        line-height: 1;
        color: $default-color;
        transform: translate(-50%, 0);
        
        @media (max-width: $phablet-width) {
          display: none;
        }
      }
    }
  }

  &__wrapper {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 140vh;
    margin: 0;
    padding: 0;
    background: $default-secondary;
    border-top: 1px solid $default-secondary;
    border-bottom: 1px solid $default-secondary;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
    
    @media (max-width: $tablet-width) {
      height: auto;
    }
    
    @media (max-width: $phablet-width) {
      top: 60px;
    }
  }

  &__list {
    position: relative;
    max-width: 1480px;
    min-height: 140vh;
    margin: 0 auto;
    padding: 20px 40px;
    list-style: none;
    
    @media (max-width: $tablet-width) {
      min-height: auto;
      padding: 20px 0;
    }
  }

  &__item {
    @media (max-width: $tablet-width) {
      margin-bottom: 1px;
    }
  }

  a {
    position: relative;
    width: 100%;
    display: block;
    padding: 14px 30px 14px 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: inherit;

    &:hover,
    &:focus {
      color: $danger-color;
      /*background-color: $default-color;*/
      outline: none;
    }

    &--active {
      color: $danger-color;
      background-color: $default-color;
    }
  }
  
  &__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    width: 36px;
    height: 46px;
    /*border: 1px solid $border-dark;  for tablet and mobile */
    background: url("/img/right-arrow-small-grey.svg") no-repeat center / 20%;
    transform: translate(0, -50%);
    
    @media (max-width: $tablet-width) {
      width: 44px;
      height: 44px;
      background: $neutral-fourth url(/img/right-arrow-small.svg) no-repeat center / 20%;
      transform: translate(0, -50%) rotate(90deg);
      transition-duration: 0.2s;
    }
    
    &--active,
    &--subactive {
      .sub-nav__list {
        display: block;
      }
    }
  }
}

.sub-nav {
  /*position: relative;*/

  &__list {
    position: absolute;
    top: 0;
    right: 0;
    left: 230px;
    width: auto;
    max-width: 1170px;
    min-height: 120vh;
    padding: 0;
    list-style: none;
    background-color: $default-color;
    
    @media (min-width: $desktop-width-only) {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
      transform: translateX(-20px);
    }
  }

  &__item {
    &:hover {
      background-color: $primary-color;

/*      .sub-nav-2__list {
        opacity: 1;
        transform: translateX(0);
        pointer-events: auto;
      }*/
    }
  }

}

.sub-nav-1 {
  max-width: 230px;
  
  @media (max-width: $tablet-width) {
    max-width: none;
    height: auto;
  }
  
  &:nth-of-type(3) {
    margin-bottom: 35px;
  }
  
  &:hover {
    color: $danger-color;
    background-color: $default-color;
      
    .sub-nav__list {
      opacity: 1;
      transform: translateX(0);
      pointer-events: auto;
    }
    
    /*> .sub-nav-2__text-block {
      opacity: 1;
      pointer-events: auto;
    }*/
  }
  
  > .sub-nav__list {
    left: 270px;
    z-index: 3;
    display: flex;
    flex-flow: column wrap;
    align-content: flex-start;
    min-height: 140vh;
    height: 100%;
    padding: 29px 0 30px;
    color: $neutral-third;
    
    @media (min-width: $desktop-width-only) and (max-width: $desktop-width) {
      width: 40%;
    }
    
    @media (max-width: $tablet-width) {
      position: relative;
      left: 0;
      z-index: 3;
      display: none;
      width: auto;
      min-height: auto;
      /* display: flex; */
      /* flex-flow: column wrap; */
      /* align-content: flex-start; */
      /* min-height: 140vh; */
      /* height: 100%; */
      padding: 29px 0 30px;
      color: #535353;
    }
/*    columns: 210px 4;
    column-gap: 5em;*/
    /*column-fill: auto;*/
    
    &::before,
    &::after {
      content: "";
      /*flex-basis: 100%;*/
      width: 0;
      order: 2;
    }
    
    &::after {
      /*position: absolute;
      top: 0;
      right: -50%;
      width: 50%;
      height: 100%;
      background-color: $default-color;*/
    }
  }
  
  &__item {
    a {
      padding: 6px 25px;
      font-weight: 700;
    }
  }
}

.sub-nav-2 {
  width: 24%;
  padding-bottom: 20px;
  
  @media (max-width: $desktop-width) {
    width: 48%;
    padding-bottom: 10px;
  }
  
  @media (max-width: $tablet-width) {
    width: 100%;
  }
  
  &:nth-child(4n+1) {
    order: 1;
  }
  
  &:nth-child(4n+2) {
    order: 2;
  }
  
  &:nth-child(4n+3) {
    order: 3;
  }
  
  &:nth-child(4n) {
    order: 4;
  }
  
  &:first-of-type {
    /*padding-top: 29px;*/
  }
  
  .main-nav__arrow {
    @media (min-width: $desktop-width-only) {
      display: none;
    }
    
    @media (max-width: $tablet-width) {
      background-position: 42%;
    }
  }
  
  > .sub-nav__list {
    position: relative;
    left: auto;
    min-height: auto;
    padding-bottom: 15px;
    font-weight: 400;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 1;
    transform: translateX(0);
    pointer-events: none;
    
    a {
      font-weight: inherit;
      
      &:hover {
        /*background-color: $primary-secondary;*/
      }
    }
  }
  /*position: relative;*/

  &__list {
 /*   position: absolute;
    top: 0;
    left: 360px;
    width: 360px;
    min-height: calc(100vh - 100px);
    padding: 0;
    list-style: none;
    background-color: $primary-color;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    transform: translateX(-20px);*/
  }

  &__item {
    /*&:hover {
      background-color: $primary-secondary;

      .sub-nav-2__text-block {
        opacity: 1;
        pointer-events: auto;
      }
    }*/
  }

  &__link[href] {
    &:hover,
    &:focus {
      /*background-color: $primary-secondary;*/
    }

    /*&:hover ~ .sub-nav-2__text-block {
      opacity: 1;
    }*/
  }


}

.sub-nav-3 {
  margin-bottom: 5px;
  
  .main-nav__arrow {
    display: none;
  }
}

.show-list {
  opacity: 1;
  transform: translateX(0);
  pointer-events: auto;
}
