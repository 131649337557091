.index-column {
  display: flex;
  flex-wrap: wrap;

  &--white-bg {
    margin: 45px 0;
    padding: 90px 80px;
    background-color: $default-color;
    border-radius: 6px;
    
    @media (max-width: $phablet-width) {
      margin: 30px 0;
      padding: 40px;
    }
    
    @media (max-width: $mobile-width) {
      padding: 40px 20px;
    }
  }

  h2 {
    margin: 0 0 20px;
    
    @media (max-width: $mobile-width) {
      margin: 0 0 10px;
    }
  }

  &__left-col,
  &__right-col {
    flex-basis: 47%;
  }

  &__left-col--narrow,
  &__right-col--narrow {
    flex-basis: 39%;
    
    @media (max-width: $phablet-width) {
      flex-basis: 43%;
    }
    
    @media (max-width: $mobile-width) {
      flex-basis: 100%;
    }
  }
  
  &__right-col--narrow {
    @media (max-width: $phablet-width) {
      flex-basis: 41%;
    }
    
    @media (max-width: $mobile-width) {
      flex-basis: 100%;
    }
  }

  &__left-col {
    margin-right: 40px;
  }

  &__left-col--narrow {
    margin-right: 160px;
    
    @media (max-width: $phablet-width) {
      margin-right: 85px;
    }
    
    @media (max-width: $mobile-width) {
      margin: 0 0 40px 0;
    }
  }

  &__btn {
    margin-top: 10px;
  }
}

.index-rows {
  &--default-third-bg {
    padding: 40px 0 120px;
    background-color: $default-third;
    
    @media (max-width: $tablet-width) {
      padding: 40px 0 60px;
    }
  }
}

.small-text {
  color: $neutral-placeholder;
}

.contacts {
  font-weight: 700;
  color: $mainFontColor;

  &--normal {
    font-weight: 400;
    text-decoration: underline;
  }

  &--text {
  }
}

.contacts[href] {
  &:hover,
  &:focus {
    color: $danger-color;
    outline: none;
  }

  &:active {
    color: $danger-secondary;
  }
}

.mt40 {
  @media (max-width: 520px) {
      margin-top: 40px;
  }
}