.search {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 2;
  display: none;
  width: 100%;
  padding: 30px 0 50px;
  text-align: center;
  color: $default-color;
  background-color: $primary-color;
  box-shadow: 0 30px 30px 0 rgba(0, 1, 1, 0.65);
  
  @media (max-width: $phablet-width) {
    top: 60px;
  }
  
  @media (max-width: $mobile-width) {
    padding: 10px 0 40px;
  }

  &__title {
    margin: 0 0 40px;
    font-size: 48px;
    line-height: 58px;
    
    @media (max-width: $phablet-width) {
      font-size: 40px;
      line-height: 50px;
    }
  
    @media (max-width: $mobile-width) {
      margin: 0 0 20px;
      font-size: 26px;
      line-height: 32px;
    }
  }
  
  &__form {
    @media (max-width: $mobile-width) {
      padding: 0 20px;
    }
  }

  &__input {
    max-width: 420px;
    width: 100%;
    margin-right: 17px;
    
    @media (max-width: $mobile-width) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &--flow {
    position: static;
    z-index: 1;
    display: block;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  
  &__btn {
    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }
}
