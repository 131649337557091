.categories {
  padding: 50px 0;
  
  @media (max-width: $mobile-width) {
    padding: 30px 0;
  }

  &__wrapper {
    padding: 0 20px;
  }

  &__title {
    margin: 0 0 40px;
    text-align: center;
    
    @media (max-width: $mobile-width) {
      margin: 0 0 15px;
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 20px;
    list-style: none;
    
    @media (max-width: $mobile-width) {
      padding: 0 10px;
    }

    figure {
      margin: 0;
    }
  }

  &__item {
    max-width: 200px;
    width: 14%;
    margin: 1.3%;
    /*max-width: 200px;
    margin: 20px;*/
    line-height: 20px;
    color: $mainFontColor;
    background-color: $default-color;
    border-radius: 6px;
    overflow: hidden;
    transition-duration: 0.15s;
    
    @media (max-width: $tablet-width) {
      width: 17.4%;
    }
    
    @media (max-width: $phablet-width) {
      width: 22.4%;
    }
    
    @media (max-width: $mobile-width) {
      width: 46%;
      margin: 2%;
    }

    &:hover,
    &:focus {
      box-shadow: 0 2px 6px 0 rgba(255, 101, 117, 0.3);
      outline: none;

      .categories__text {
        color: $danger-color;
      }
    }

    &:active {
      box-shadow: 0 2px 3px 0 rgba(255, 101, 117, 0.2);

      p {
        color: $danger-third;
      }
    }
  }

  &__text {
    min-height: 95px;
    padding: 6px 15px 10px;
    
    @media (max-width: $mobile-width) {
      min-height: 70px;
      padding: 6px 7px 10px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &--binding {
    .container {
      max-width: 1520px;
    }
    
    .categories__list {
      max-width: none;
      margin: 0;
      padding: 0;
    }
  }
}
