.table {
  margin: 0 auto 20px;
  border-collapse: collapse;
  
  th,
  td {
    padding: 8px 20px;
    
    @media (max-width: $mobile-width) {
      padding: 6px 10px;
      font-size: 12px;
    }
  }
  
  &__body {
    tr {
      &:nth-of-type(odd) {
        background-color: $default-color;
      }
      
      &:hover {
        background-color: $warning-color;
      }
    }
    
    td {
      text-align: center;
    }
  }
  
}