.additional {
  padding: 70px 0;
  
  @media (max-width: $tablet-width) {
    padding: 50px 0;
  }
  
  @media (max-width: $phablet-width) {
    padding: 30px 0;
  }
  
  @media (max-width: $mobile-width) {
    padding: 20px 0;
  }

  &__title {
    text-align: center;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    position: relative;
    width: 14.5%;
    margin: 65px 6.5% 65px 0;
    color: $mainFontColor;
    background-color: transparent;
    
    @media (max-width: $tablet-width) {
      margin: 40px 6.5% 40px 0;
    }
    
    @media (max-width: $phablet-width) {
      width: 25%;
      margin: 30px 12.5% 30px 0;
    }
    
    @media (max-width: $mobile-width) {
      width: 45%;
      margin: 20px 10% 20px 0;
      font-size: 12px;
    }
    
    @media (min-width: $tablet-width-only) {
      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }
    
    @media (min-width: $mobile-width-only) and (max-width: $phablet-width) {
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    
    @media (max-width: $mobile-width) {
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }

  img {
    width: 120px;
    height: 120px;
    border-radius: 6px;
  }

  b {
    display: block;
    margin-top: 10px;
  }

  &-col-4 {
    .additional__item {
      width: 17%;
      margin: 65px 10.5% 65px 0;

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }

  &-col-3 {
    .additional__item {
      width: 26%;
      margin: 80px 11% 80px 0;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
}
