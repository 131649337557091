.hot {
  display: flex;
  margin-top: 140px;
  
  @media (max-width: $tablet-width) {
    margin-top: 50px;
  }
  
  @media (max-width: $phablet-width) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
  
  @media (max-width: $mobile-width) {
    margin-top: 20px;
  }

  &__title {
    width: 31%;
    margin: 0 3% 40px 0;
    
    @media (max-width: $tablet-width) {
      width: 25%;
      font-size: 26px;
      line-height: 34px;
    }
    
    @media (max-width: $phablet-width) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
    
    @media (max-width: $mobile-width) {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__list {
    align-items: stretch;
    width: 66%;
    padding: 0;
    
    @media (max-width: $tablet-width) {
      width: 70%;
    }
    
    @media (max-width: $phablet-width) {
      width: 100%;
    }
    
    @media (max-width: $mobile-width) {
      justify-content: center;
    }
  }

  &__link {
    max-width: 300px;
    display: inline-block;
    vertical-align: top;
    color: $mainFontColor;
    text-decoration: underline;
    
    @media (max-width: $phablet-width) {
      max-width: none;
    }

    &:hover,
    &:focus {
      color: $danger-color;
      outline: none;
    }

    &:active {
      color: $danger-secondary;
    }
  }

  &__item {
    max-width: none;
    width: 21.7%;
    margin: 0 4% 4% 0;
    
    @media (max-width: $tablet-width) {
      width: 22.7%;
      margin: 0 3% 3% 0;
    }
    
    @media (max-width: $phablet-width) {
      max-width: 200px;
    }

    @media (min-width: $mobile-width-only) {
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
    
    @media (max-width: $mobile-width) {
      max-width: none;
      width: 48%;
      margin: 0 4% 3% 0;
      
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
}
