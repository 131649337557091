

.user-checkbox {
  display: block;
  margin-top: 10px;
  line-height: 1.8rem;

  label {
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    font: {
      size: 1.4rem;
      style: italic;
    }
    line-height: 1.8rem;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      z-index: 1;
      width: 9px;
      height: 9px;
      border: 2px solid #5a5a5a;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
  }

  input {
    &[type="checkbox"] {
      display: none;

      &:checked ~ label::before {
        width: 12px;
        height: 6px;
        border-color: #00bad2;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
      }
    }
  }
}