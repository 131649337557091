html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  min-width: 320px;
  margin: 0;
  font-family: $font;
  font-size: $mainFontSize;
  font-weight: normal;
  line-height: $mainLineHeight;
  color: $mainFontColor;
  background-color: $default-color;
  
  @media (max-width: $mobile-width) {
    font-size: 14px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 42px;

  @media (max-width: $phablet-width) {
    font-size: 30px;
  }
  
  @media (max-width: $mobile-width) {
    font-size: 24px;
  }
}

h2 {
  font-size: 24px;
  
  @media (max-width: $phablet-width) {
    font-size: 18px;
  }
  
  @media (max-width: $mobile-width) {
    font-size: 14px;
  }
}

h3 {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.75;

  + p {
    margin-top: 0;
  }
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  
  @media (max-width: $tablet-width) {
    padding: 0 30px;
  }
  
  @media (max-width: $mobile-width) {
    padding: 0 20px;
  }
  
  &--header {
    @media (max-width: $phablet-width) {
      padding: 0;
    }
  }

  &--default-bg,
  &--primary-bg {
    border-radius: 6px;
  }

  &--default-bg {
    background-color: $default-color;
  }

  &--primary-bg {
    background-color: $primary-color;
  }

  &--top {
    display: flex;
  }


  &--narrow {
    max-width: 1000px;
    /*outline: 1px solid $success-color;*/
  }

  &--narrowest {
    max-width: 760px;
    /*outline: 1px solid $danger-color;*/
  }
  
  &--without-indent {
    @media (max-width: $mobile-width) {
      padding: 0;
    }
  }
}

.btn {
  display: inline-block;
  min-width: 200px;
  padding: 14px 25px;
  font: inherit;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: $default-color;
  vertical-align: top;
  text-decoration: none;
  background-color: $danger-color;
  border: 0;
  border-radius: 40px;
  cursor: pointer;

  @media (max-width: $phablet-width) {
    min-width: 150px;
    padding: 12px 14px;
    font-size: 12px;
  }
  
  @media (max-width: $mobile-width) {
    padding: 7px 10px;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:active {

  }

  &--dark {
    background-color: $danger-third;
  }

  &--disabled {
    background-color: $neutral-primary;
    opacity: 0.8;
    cursor: default;
  }
}



.btn--red {
  color: $default-color;
  background-color: $danger-color;
  border: 1px solid $danger-color;

  &:hover,
  &:focus {
    color: $default-color;
    background-color: $danger-secondary;
    border: 1px solid $danger-secondary;
    box-shadow: 0 2px 4px 0 $danger-third;
  }
}

.btn--white {
  color: $default-color;
  border: 1px solid $default-color;

  &:hover,
  &:focus {
    color: $danger-color;
    border: 1px solid $danger-color;
  }
}

.input {
  padding: 16px 10px;
  border: none;
  border-radius: 6px;
  @include placeholder($font, 16px, $neutral-placeholder);

  &:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $danger-color;
    outline: none;
  }
  
  @media (max-width: $mobile-width) {
    padding: 12px 10px;
  }
}

small {
  display: block;
  line-height: 20px;
  
  @media (max-width: $mobile-width) {
    display: inline-block;  
  }
}

.no-display-mobile {
  @media (max-width: $mobile-width) {
    display: none;
  }
}
