.top {
  display: flex;
  width: 100%;
  min-height: 760px;
  background: $neutral-secondary url("/img/bg-header.jpg") center top / 100% no-repeat;
  overflow: hidden;

  @media (max-width: $tablet-width) {
    min-height: 560px;
  }

  @media (max-width: $phablet-width) {
    min-height: 460px;
  }
  
  @media (max-width: $mobile-width) {
    min-height: auto;
    background: none;
    background-color: $neutral-secondary;
  }

  &__wrapper {
    position: relative;
  }

  &__logo {
    display: block;
    width: 160px;
    margin: 20px 0;

    @media (min-width: $mobile-width-only) {
      display: none;
    }
  }

  &__list {
    position: absolute;
    bottom: 100px;
    left: 40px;

    @media (max-width: $mobile-width) {
      position: static;
    }

  }

  &__text,
  &__body {
    position: absolute;
    bottom: 0;
    left: 40px;

    @media (max-width: $mobile-width) {
      position: static;
    }

    p {
      margin-bottom: 0;
    }

    a {
      /*text-decoration: underline;*/
    }
  }

  &__text {
    bottom: 30px;

    @media (max-width: $mobile-width) {
      margin-bottom: 35px;
    }
  }

  &--inner {
    background: $neutral-secondary url("/img/bg-inner.jpg") center top / 100% no-repeat;

    @media (max-width: $tablet-width) {
      min-height: 760px;
    }

    @media (max-width: $phablet-width) {
      min-height: 760px;
      background: $neutral-secondary url("/img/bg-inner-tablet.jpg") center top / 100% no-repeat;
    }

    @media (max-width: $mobile-width) {
      /*background: $neutral-secondary url("/img/bg-inner-mobile.jpg") center top / 100% no-repeat;*/
      min-height: auto;
      background-image: none;
      background-color: $neutral-secondary;
    }
  }

  &__body {
    max-width: 550px;
    font-size: 18px;
  }

  &__video {
    max-width: 460px;
    max-height: 255px;
    margin: 20px 0 30px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.2);

    iframe {
      border: 0;

      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }
  }

  &__mark {
    color: $warning-color;
    font-weight: 700;
    text-decoration: none;
  }
}
