.site-list {
  &-bottom {
    display: flex;
    flex-wrap: wrap;
    
    &__item {
      width: 24%;
      margin-right: 1%;
      
      @media (max-width: $phablet-width) {
        width: 32%;
      }
      
      @media (max-width: $mobile-width) {
        width: 100%;
        margin-right: 0;
      }
      
      @media (min-width: $tablet-width-only) {
        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }
      
      @media (min-width: $mobile-width-only) and (max-width: $phablet-width) {
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }
}