.feedback {
  margin: 50px 0 100px;
  padding: 100px 30px;
  font-family: $font;
  background-color: $primary-color;
  border-radius: 6px;
  
  @media (max-width: $mobile-width) {
    margin: 40px 0 70px;
    padding: 50px 30px;
  }

  &__title {
    margin: 0 0 40px;
    color: $default-color;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;

    &--align {
      margin: 0 auto;
      text-align: center;
    }
  }

  &__input {
    max-width: 200px;
    margin: 0 20px 20px 0;

    &::placeholder {
      font-family: inherit;
    }
  }

  &__btn {
    margin-bottom: 10px;
    
    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }

  &__small-text {
    text-align: center;
    
    @media (max-width: $mobile-width) {
      line-height: 16px;
    }
  }
}
