.brands {
  padding: 140px 0;
  color: $default-color;
  background-color: $primary-color;
  
  @media (max-width: $phablet-width) {
    padding: 70px 0;
  }
  
  @media (max-width: $mobile-width) {
    margin-top: 60px;
  }

  &__title {
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
    
    @media (max-width: $phablet-width) {
      margin-bottom: 25px;
    }
  }
  
  @media (max-width: $phablet-width) {}

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1060px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px;
    background-color: transparent;
    
    @media (max-width: $tablet-width) {
      padding: 35px;
    }
    
    @media (max-width: $phablet-width) {
      padding: 0 30px;
    }
    
    @media (max-width: $mobile-width) {
      img {
        width: 130px;
      }
    }
  }

  &__align {
    text-align: center;
  }
}