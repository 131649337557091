/* Animations */
@keyframes bounce {
  0% {
    transform: translateY(-2000px);
  }

  70% {
    transform: translateY(30px);
  }

  90% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounceModal {
  0% {
    transform: translate(-50%, -500%);
  }

  70% {
    transform: translate(-50%, -30%);
  }

  90% {
    transform: translate(-50%, -60%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes slideLeft {
  0% {
    transform: translate(-2000px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(1000000px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translate(-50%, -50%);
  }

  80% {
    transform: translate(-50%, -60%);
  }

  100% {
    transform: translate(-50%, -500%);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translate(-50%, -50%);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-48%, -50%);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate(-52%, -50%);
  }
}

/* other */
@keyframes swing {
  25%, 100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  5% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  10% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  15% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  20% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes tada {
  0%, 40% {
    -webkit-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }

  5%, 15%, 25% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg);
  }

  10%, 20%, 30% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

.anim-tada {
  animation-name: tada;
  animation-duration: 8s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
