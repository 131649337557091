.preferences {
  padding: 70px 0;
  
  @media (max-width: $tablet-width) {
    padding: 60px 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    position: relative;
    width: 14.5%;
    margin: 65px 6.5% 65px 0;
    padding-top: 100px;
    color: $mainFontColor;
    background-color: transparent;
    /*outline: 1px solid #ccc;*/
    
    @media (max-width: $tablet-width) {
      margin: 30px 6.5% 30px 0;
    }
    
    @media (max-width: $phablet-width) {
      width: 29%;
    }
    
    @media (min-width: $mobile-width-only) and (max-width: $phablet-width) {
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
    
    @media (max-width: $mobile-width) {
      width: 44.5%;
      font-size: 12px;
      
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }

    @media (min-width: $tablet-width-only) {
      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 80px;
    }

    b {
      display: block;
      font-weight: 700;
    }

    &--icon-1 {
      &::before {
        background: url("/img/icons/icons-06.svg") no-repeat center;
      }
    }

    &--icon-2 {
      &::before {
        background: url("/img/icons/icons-04.svg") no-repeat center;
      }
    }

    &--icon-3 {
      &::before {
        background: url("/img/icons/icons-15.svg") no-repeat center;
      }
    }

    &--icon-4 {
      &::before {
        background: url("/img/icons/icons-16.svg") no-repeat center;
      }
    }

    &--icon-5 {
      &::before {
        background: url("/img/icons/icons-17.svg") no-repeat center;
      }
    }

    &--icon-6 {
      &::before {
        background: url("/img/icons/icons-18.svg") no-repeat center;
      }
    }

    &--icon-7 {
      &::before {
        background: url("/img/icons/icons-14.svg") no-repeat center;
      }
    }

    &--icon-8 {
      &::before {
        background: url("/img/icons/icons-19.svg") no-repeat center;
      }
    }
  }

  &-col-4 {
    .preferences__item {
      width: 17%;
      margin: 65px 10.5% 65px 0;
      
      @media (max-width: $tablet-width) {
        margin: 30px 10.5% 30px 0;
      }
      
      @media (min-width: $tablet-width-only) {
        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }
      
      @media (max-width: $phablet-width) {
        width: 26%;
      }
      
      @media (min-width: $mobile-width-only) and (max-width: $phablet-width) {
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
      
      @media (max-width: $mobile-width) {
        width: 44.5%;
        
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }

  &-col-3 {
    .preferences__item {
      width: 26%;
      margin: 80px 11% 80px 0;
      
      @media (max-width: $tablet-width) {
        margin: 40px 11% 40px 0;
      }

      @media (min-width: $mobile-width-only) {
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
      
      @media (max-width: $mobile-width) {
        width: 44.5%;
        
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }
}