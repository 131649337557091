.catalog {
  padding: 100px 0;
  background-color: $neutral-secondary;

  @media (max-width: $phablet-width) {
    padding: 40px 0;
  }

  @media (max-width: $mobile-width) {
    padding: 40px 0;
  }

  &__wrapper {

  }

  &__list {
    padding: 0;
    counter-reset: list;

    @media (max-width: $mobile-width) {
      justify-content: center;
    }
  }

  &__item {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding-left: 60px;

    @media (max-width: $phablet-width) {
      align-items: flex-start;
    }

    @media (max-width: $mobile-width) {
      max-width: 400px;
      margin: 0 auto 50px;
      padding-left: 0;
      text-align: center;
    }

    &::before {
      counter-increment: list;
      content: "" counter(list);
      position: absolute;
      right: 95%;
      z-index: -1;
      font-size: 80px;
      font-weight: 700;
      line-height: 1;
      color: $neutral-regular;

      @media (max-width: $phablet-width) {
        top: 0;
        font-size: 40px;
      }

      @media (max-width: $mobile-width) {

        right: 50%;
        font-size: 28px;
        transform: translate(50%, 0);
      }
    }

    &:nth-of-type(3) {
      img {
        width: 160px;
      }
    }

    &:nth-of-type(4),
    &:nth-of-type(6) {
      img {
        width: 240px;
      }
    }

    &:nth-of-type(5) {
      img {
        width: 120px;
      }
    }

    &:nth-of-type(7),
    &:nth-of-type(8),
    &:nth-of-type(9),
    &:nth-of-type(10) {
      img {
        width: 210px;
        transform: rotate(6deg);
      }
    }

    &-body {
      width: 40%;

      @media (max-width: $mobile-width) {
        order: 2;
        width: 100%;
        font-size: 12px;
      }
    }

    &-title {
      margin: 10px 0 20px;
      font-size: 30px;
      line-height: 1.45;
      letter-spacing: 3px;
      text-transform: uppercase;

      @media (max-width: $mobile-width) {
        margin: 5px 0 15px;
        font-size: 18px;
      }
    }

    &-img {
      width: 300px;
      margin: 0 auto;

      @media (max-width: $mobile-width) {
        order: 1;
        margin: 45px auto 30px;
      }
    }
  }

  &__type {
    font-size: 14px;
    color: $neutral-primary;

    @media (max-width: $mobile-width) {
      font-size: 12px;
    }
  }

  &--3-col {
    background-color: $default-color;

    .container {
      max-width: 1000px;
    }

    .catalog__video {
      min-width: 260px;
      width: 40%;

      @media (max-width: $phablet-width) {
        order: 3;
      }

      @media (max-width: $mobile-width) {
        width: 100%;
      }

      p {
        margin: 0;
      }
    }

    .catalog__item-body,
    .catalog__item-img {
      width: 30%;

      @media (max-width: $phablet-width) {
        width: 40%;
      }

      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }
  }

  iframe {
    border: 0;

    @media (max-width: $mobile-width) {
      width: 100%;
    }
  }
}
