.steps {
  &__title {
    margin: 0 0 80px;
    text-align: center;

    @media (max-width: $phablet-width) {
      margin: 0 0 40px;
    }
  }

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: list;

    @media (max-width: $mobile-width) {
      flex-wrap: wrap;
    }
  }

  &__item {
    position: relative;
    z-index: 20;
    flex-basis: 30%;
    margin-right: 20px;
    padding-top: 100px;

    @media (max-width: $phablet-width) {
      padding-top: 70px;
    }

    @media (max-width: $mobile-width) {
      flex-basis: 100%;
      padding: 0 0 0 20px;
      margin-bottom: 20px;
    }

    &-title {
      margin: 0 0 15px;
    }

    &::before {
      counter-increment: list;
      content: "" counter(list);
      position: absolute;
      top: -6px;
      left: 50%;
      z-index: -1;
      font-size: 120px;
      font-weight: 700;
      line-height: 1;
      color: $neutral-regular;
      transform: translate(-50%, 0);

      @media (max-width: $phablet-width) {
        top: 0;
        font-size: 80px;
      }

      @media (max-width: $mobile-width) {
        top: 7px;
        left: 0;
        font-size: 18px;
      }
    }
  }

  &__inactive {
    color: $neutral-placeholder;
  }

  &--full {
    padding: 140px 0;

    @media (max-width: $phablet-width) {
      padding: 50px 0;
    }

    .steps__list {
      justify-content: space-between;

      @media (max-width: $phablet-width) {
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }

    .steps__item {
      padding: 0 0 0 70px;

      @media (max-width: $phablet-width) {
        flex-basis: 100%;
        margin-bottom: 40px;
        padding: 0 0 0 55px;
        font-size: 14px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &::before {
        top: 0;
        left: 0;
        font-size: 24px;
        width: 45px;
        height: 45px;
        line-height: 42px;
        text-align: center;
        color: $default-color;
        background-color: $danger-color;
        border-radius: 50%;
        transform: translate(0, 0);

        @media (max-width: $phablet-width) {
          font-size: 18px;
          width: 35px;
          height: 35px;
          line-height: 35px;
        }
      }

      &:nth-of-type(2) {
        &::before {
          background-color: $danger-secondary;
        }
      }

      &:nth-of-type(3) {
        &::before {
          background-color: $danger-third;
        }
      }

    }
  }
}
