.note {
  max-width: 360px;
  padding: 0 0 0 30px;
  font-size: 24px;
  line-height: 1.58;
  border-left: 10px solid $primary-color;
  
  @media (max-width: $mobile-width) {
    padding: 0 0 0 20px;
    font-size: 18px;
    border-left: 6px solid #364ae3;
  }

  &--indent {
    margin: 110px auto;
    
    @media (max-width: $phablet-width) {
      margin: 55px auto;
    }
  }
}
